import React, { FC } from "react";
import { Box, Checkbox, CheckboxProps } from "@chakra-ui/react";

export interface ICreateAccountConditionsProps {
	checkboxes: (CheckboxProps & { required?: boolean })[];
}

const CreateAccountConditions: FC<ICreateAccountConditionsProps> = ({
	checkboxes = [],
}) => {
	return (
		<Box>
			{checkboxes.map((checkbox) => (
				<Checkbox {...checkbox} />
			))}
		</Box>
	);
};

export default CreateAccountConditions;
