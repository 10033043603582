import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { IJobRequest } from "@interfaces/api/job-requests.types";
import convertToFormData from "@utils/convert-to-form-data";

export const createFile = async (data: any): Promise<IJobRequest> => {
	try {
		const axios = axiosClient({
			headers: { "Content-type": "multipart/form-data" },
		});
		const form = convertToFormData(data);
		const resp: AxiosResponse<IJobRequest> = await axios.post(`Files`, form);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const deleteFile = async (id: number): Promise<void> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IJobRequest> = await axios.delete(`Files/${id}`);
	} catch (err: any) {
		throw err.response;
	}
};
