import React, { FC, ReactNode, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
	Center,
	useColorModeValue,
	Icon,
	HStack,
	Text,
	Spinner,
	VStack,
	Heading,
} from "@chakra-ui/react";
import { MdOutlineCloudUpload } from "react-icons/md";

interface DocumentsUploaderProps {
	children?: ReactNode;
	onFileAccepted: (file: File) => void;
	label?: string;
}

const DocumentsUploader: FC<DocumentsUploaderProps> = ({
	onFileAccepted,
	label,
}) => {
	const [uploading, setUploading] = useState(false);
	const onDrop = useCallback(
		(acceptedFiles) => {
			uploadFiles(acceptedFiles[0]);
		},
		[onFileAccepted],
	);

	const uploadFiles = async (file: File) => {
		try {
			setUploading(true);
			await onFileAccepted(file);
		} catch (e) {
			console.log(e);
		} finally {
			setUploading(false);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		multiple: false,
		accept: {
			"application/pdf": [".pdf"],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				[".docx"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
			"application/msword": [".doc"],
			"application/vnd.ms-excel": [".xls"],
			"image/png": [".png"],
			"image/jpeg": [".jpeg", ".jpg"],
		},
	});

	const dropText =
		label || isDragActive
			? "Drop the files here ..."
			: "Drag 'n' drop .pdf document here, or click to select files";

	const activeBg = useColorModeValue("gray.200", "gray.600");
	const borderColor = useColorModeValue("gray.300", "gray.400");

	return (
		<Center
			p={4}
			py={8}
			cursor="pointer"
			bg={isDragActive ? activeBg : "transparent"}
			_hover={{ bg: activeBg }}
			transition="background-color 0.2s ease"
			borderRadius={4}
			mx={"auto"}
			w={"100%"}
			maxW={"1000px"}
			border="2px dashed"
			borderColor={borderColor}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<HStack align={"center"} justify={"center"}>
				{uploading ? (
					<Spinner />
				) : (
					<>
						<Icon as={MdOutlineCloudUpload} fontSize={"26px"} />
						<Text fontSize={"20px"}>{dropText}</Text>
					</>
				)}
			</HStack>
		</Center>
	);
};

export default DocumentsUploader;
