export enum FileTypesEnum {
	"Default",
	"CV",
	"Document",
	"Avatar",
	"Logo",
}

export interface IUploadedFile {
	id: number;
	name: string;
	url: string;
	extension: string;
	type: FileTypesEnum;
}
