import React from "react";
import { Box, Tooltip, TooltipProps } from "@chakra-ui/react";

export type ITooltipIcon = Omit<TooltipProps, "children"> & {
	icon: JSX.Element;
	tooltip: string;
};

export const TooltipIcon: React.FC<ITooltipIcon> = ({
	icon,
	tooltip,
	...rest
}) => {
	return (
		<Tooltip label={tooltip} {...rest}>
			<Box>{icon}</Box>
		</Tooltip>
	);
};

export default TooltipIcon;
