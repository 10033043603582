import React, { FC, useState } from "react";
import LogIn from "@components/views/auth/log-in";
import DocumentsUploader from "../../cv/pdf-uploader/documents-uploader";
import {
	Box,
	Center,
	Heading,
	HStack,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { FileTypesEnum } from "@interfaces/api/file-upload.types";
import {
	IUnverifiedUser,
	IUser,
	IUserForm,
	IUserFormData,
	UserTypeEnum,
} from "@interfaces/api/auth.types";
import { createFile } from "@api/files.api";
import UserForm, { UserFormProps } from "@components/forms/user-form/user-form";
import { IApiError } from "@interfaces/api/api.types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createUser, resetPassword, updateUser } from "@api/auth.api";
import { writeToStorage } from "@utils/local-storage";
import { LSTemporaryToken } from "@definitions/local-storage.keys";
import { Button } from "@components";
import colors, {
	brandColor,
} from "@definitions/chakra/theme/foundations/colors";
import PdfViewer from "../../cv/pdf-viewer/pdf-viewer";
import { MainButton } from "@components/buttons/main-button/main-button";
import useAuthContext from "@hooks/useAuthContext";
import { useRouter } from "next/router";
import { jobOfferQK, passwordQK, userQK } from "@definitions/react-query.keys";
import { getJobOffer } from "@api/job-offers.api";
import TooltipIcon from "@components/tooltip-icon/tooltip-icon";
import { IoLocationSharp } from "react-icons/io5";
import TextWithPlaceholder from "@components/text-with-placeholder";
import { GiGraduateCap } from "react-icons/gi";
import { FaLevelUpAlt } from "react-icons/fa";
import CreateAccountConditions from "@components/form/terms-&-conditions/create-account-conditions";
import useConditionsCheck from "@hooks/useConditionsCheck";
import SvgLogoIcon from "@components/icons/Logo";
const ICON_SIZE = "28px";

interface CandidateCreationProps {
	onCreated: (data?: any & { id: number }) => void;
	userFormProps?: Omit<UserFormProps, "onSubmit">;
}

const CandidateCreation: FC<CandidateCreationProps> = ({
	onCreated,
	userFormProps = {},
}) => {
	const toast = useToast();
	const router = useRouter();
	const { authToken, authData, updateAuthData } = useAuthContext();
	const { allRequiredChecked, onConditionsChange } = useConditionsCheck();

	const { id } = router.query;
	const jobOfferId = Number(id);

	const [cv, setCv] = useState<File | null>(null);
	const [cvUrl, setCvUrl] = useState<string | null>(null);
	const [userData, setUserData] = useState<IUser | null>(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [haveAccount, setHaveAccount] = useState(false);
	const [tempEmail, setTempEmail] = useState("");

	const { isOpen, onOpen, onClose } = useDisclosure();

	const changeTab = (index: number) => setCurrentTab(index);

	const { data: jobOffer } = useQuery({
		queryKey: jobOfferQK([{ id: jobOfferId }]),
		queryFn: () => getJobOffer(jobOfferId),
		enabled: !!id,
	});

	const onCreateSuccess = async (data: IUnverifiedUser) => {
		toast({
			title: `Thank you, ${authData?.firstName || data.firstName || "friend"}!`,
			description:
				!!authData && authData?.role.value !== UserTypeEnum.Candidate
					? "Candidate profile has been created!"
					: "You've successfully registered on our platform.",
			status: "success",
			duration: 4000,
			isClosable: true,
		});
		if (!authData) {
			writeToStorage(LSTemporaryToken, data.temporaryToken);
			updateAuthData(data);
		}
		setUserData(data);
		changeTab(1);
	};

	const onUpdateSuccess = async (data: IUser | void) => {
		toast({
			title: `Success!`,
			description:
				!!authData && authData?.role.value !== UserTypeEnum.Candidate
					? "Candidate profile has been updated!"
					: "Your data has been updated",
			status: "success",
			duration: 4000,
			isClosable: true,
		});
		if (!authData && data) {
			updateAuthData(data);
		}
		data && setUserData(data);
		changeTab(1);
	};

	const onCreateError = (error: IApiError) => {
		if (authToken) {
			toast({
				title: error?.data?.codeString || "Request failed",
				description: error?.data?.error || "Something went wrong",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
			return;
		}
		onOpen();
	};

	const onResetPasswordSuccess = async () => {
		toast({
			title: "Request for resetting password has been sent!",
			description: "Please follow the instructions in email",
			status: "success",
			duration: 4000,
			isClosable: true,
		});
		setHaveAccount(true);
		onClose();
	};

	const onError = (error: IApiError) => {
		toast({
			title: error?.data?.codeString || "Request failed",
			description: error?.data?.error || "Something went wrong",
			status: "error",
			duration: 4000,
			isClosable: true,
		});
	};

	const createUserQuery = useMutation(createUser, {
		onSuccess: onCreateSuccess,
		onError: onCreateError,
	});

	const updateUserQuery = useMutation<
		IUser | void,
		IApiError,
		{ form: Partial<IUserForm>; id: number | null }
	>(async (vars) => updateUser(vars.form, vars.id), {
		mutationKey: userQK([{ id: userData?.id }]),
		onSuccess: onUpdateSuccess,
		onError: onCreateError,
	});

	const resetPasswordQuery = useMutation(resetPassword, {
		mutationKey: passwordQK(),
		onSuccess: onResetPasswordSuccess,
		onError: onError,
	});

	const onUserSubmit = async (values: Partial<IUserFormData>) => {
		if (userData?.id) {
			await updateUserQuery.mutateAsync({
				form: values,
				id:
					!!authData && authData?.role.value !== UserTypeEnum.Candidate
						? userData.id
						: null,
			});
			return;
		}

		values.login && setTempEmail(values.login);
		await createUserQuery.mutateAsync(values);
	};

	const uploadFile = async (file: File | null, type: FileTypesEnum) => {
		try {
			await createFile({
				items: file,
				extension: file?.type,
				type,
				userId: userData?.id,
			});
		} catch (e: any) {
			// onUpdateError(e?.response);
		}
	};

	const onFileInput = async (file: File) => {
		setCv(file);
		setCvUrl(URL.createObjectURL(file));
		await uploadFile(file, FileTypesEnum.CV);
	};

	const onFinish = async () => {
		try {
			await onCreated(userData);
		} catch (e) {
			console.log(e);
		}
	};

	const goNext = (index: number) => {
		setCurrentTab(index);
	};

	const buttonBg = useColorModeValue(brandColor[0], brandColor[1]);
	const activeColor = useColorModeValue("white", "white");
	const textColor = useColorModeValue("white", "white");

	return (
		<>
			{!!jobOffer && (
				<>
					<Box
						position={"relative"}
						w={"100%"}
						overflow={"hidden"}
						borderTopLeftRadius={5}
						borderTopRightRadius={5}
					>
						<Box
							h={{ base: "300px", lg: "280px" }}
							style={{
								width: "100%",
								filter: "saturate(0.4)",
								background: `url(/banner.jpeg)`,
								backgroundSize: "cover",
								backgroundPositionY: "bottom",
							}}
						/>
						<Stack
							direction={{ base: "column", lg: "row" }}
							spacing={{ base: 4, lg: 20 }}
							position={"absolute"}
							top={0}
							width={"100%"}
							height={"100%"}
							alignItems={"center"}
							justifyContent={"center"}
							backgroundColor={colors.brand["700"] + "E6"}
							p={4}
						>
							<SvgLogoIcon color={"white"} width={"240px"} height={"240px"} />
							<VStack spacing={6}>
								<Heading
									color={"white"}
									fontSize={{ base: "18px", lg: "32px" }}
								>
									{jobOffer?.title}
								</Heading>
								<VStack
									align={"start"}
									spacing={4}
									color={"white"}
									w={"100%"}
									fontSize={{ base: "16px", lg: "24px" }}
								>
									<HStack justify={"start"}>
										<TooltipIcon
											icon={
												<IoLocationSharp size={ICON_SIZE} color={"white"} />
											}
											tooltip={"Location"}
										/>
										<TextWithPlaceholder
											text={`${jobOffer?.location?.city}, ${jobOffer?.location?.country}`}
											fontWeight={600}
										/>
									</HStack>
									<HStack justify={"start"}>
										<TooltipIcon
											icon={<GiGraduateCap size={ICON_SIZE} color={"white"} />}
											tooltip={"Required education"}
										/>
										<TextWithPlaceholder
											text={jobOffer?.jobEducationNeeded?.label}
											fontWeight={600}
										/>
									</HStack>
									<HStack justify={"start"}>
										<TooltipIcon
											icon={<FaLevelUpAlt size={ICON_SIZE} color={"white"} />}
											tooltip={"Required experience"}
										/>
										<TextWithPlaceholder
											text={jobOffer?.jobLevelExperience?.label}
											fontWeight={600}
										/>
									</HStack>
								</VStack>
							</VStack>
						</Stack>
					</Box>
				</>
			)}
			<Tabs isFitted index={currentTab} onChange={changeTab}>
				<TabList style={{ height: 0, overflow: "hidden" }}>
					<Tab>Step 1</Tab>
					<Tab isDisabled={!userData?.id}>Step 2</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						{haveAccount ? (
							<>
								<VStack justify={"center"} align={"center"} mb={4}>
									<Heading size={"md"} fontWeight={"normal"} m={0}>
										{haveAccount
											? "Do you wish to create a new account?"
											: "Already have an account? You don't have to fill your data again - sign in make sure your data is up to date"}
									</Heading>
									<Button
										variant={"link"}
										p={0}
										onClick={() => setHaveAccount(!haveAccount)}
									>
										{haveAccount ? "Create account" : "Log in"}
									</Button>
								</VStack>
								<LogIn
									onSuccess={onCreated}
									initValues={tempEmail ? { login: tempEmail } : undefined}
								/>
							</>
						) : (
							<>
								<Center my={3}>
									<Stack
										direction={{ base: "column", lg: "row" }}
										align={"end"}
										justify={"center"}
									>
										<VStack>
											<Heading fontSize={{ base: "24px", lg: "32px" }}>
												Step 1
											</Heading>
											<Heading
												fontSize={{ base: "22px", lg: "30px" }}
												fontWeight={"600"}
											>
												Fill in your information
											</Heading>
											<VStack justify={"center"} align={"center"} mb={4}>
												<Heading
													fontSize={{ base: "18px", lg: "26px" }}
													fontWeight={"normal"}
													m={0}
												>
													{haveAccount
														? "Do you wish to create a new account?"
														: "Already have an account? You don't have to fill your data again - sign in make sure your data is up to date"}
												</Heading>
												<Button
													variant={"link"}
													p={0}
													onClick={() => setHaveAccount(!haveAccount)}
												>
													{haveAccount ? "Create account" : "Sign in"}
												</Button>
											</VStack>
										</VStack>
									</Stack>
								</Center>
								<UserForm
									onSubmit={onUserSubmit}
									viewerType={authData?.role.value}
									submitButtonLabel={"Next step"}
									submitButtonProps={{
										disabled: !authData && !allRequiredChecked,
									}}
									shouldShowUnsavedWarnings={false}
									{...userFormProps}
								>
									{!authData && (
										<Center my={4}>
											<CreateAccountConditions
												checkboxes={[
													{
														required: true,
														children: (
															<Text mb={0}>
																I agree to{" "}
																<Link
																	color={"brand.700"}
																	href={
																		"https://www.elkho-group.com/en/legal-information/"
																	}
																	target={"_blank"}
																>
																	Legal terms
																</Link>{" "}
																and{" "}
																<Link
																	color={"brand.700"}
																	href={
																		"https://www.elkho-group.com/en/privacy-policy/"
																	}
																	target={"_blank"}
																>
																	Privacy Policy
																</Link>{" "}
																of Elkho Group
															</Text>
														),
														onChange: (ev) =>
															onConditionsChange([
																{
																	requried: true,
																	isChecked: ev.target.checked,
																},
															]),
													},
												]}
											/>
										</Center>
									)}
								</UserForm>
							</>
						)}
						<Stack
							direction={{ base: "column", lg: "row" }}
							justify={"start"}
							align={"center"}
							my={2}
						>
							<Heading size={"sm"} fontWeight={"normal"} m={0}>
								Still haven't found what you're looking for? Leave us your CV
								and we'll contact you once we have a job offer that corresponds
								to your profile.
							</Heading>
							<Button
								variant={"link"}
								p={0}
								onClick={() => router.push("/auth/register")}
							>
								Click here
							</Button>
						</Stack>
					</TabPanel>
					<TabPanel>
						<Center my={3}>
							<Stack
								direction={{ base: "column", lg: "row" }}
								align={{ base: "center", lg: "start" }}
								justify={"center"}
							>
								<MainButton
									onClick={() => goNext(0)}
									disabled={!userData?.id}
									mt={1}
								>
									Back
								</MainButton>
								<VStack mt={0} pt={0}>
									<Heading fontSize={{ base: "24px", lg: "32px" }}>
										Step 2
									</Heading>
									<Heading
										fontSize={{ base: "22px", lg: "30px" }}
										fontWeight={"600"}
									>
										Upload your CV in PDF file
									</Heading>
								</VStack>
								<MainButton
									onClick={onFinish}
									disabled={
										authData?.role.value === UserTypeEnum.Candidate &&
										(!userData?.id || (!cvUrl && !userData?.cv))
									}
									mt={1}
								>
									Finish applying
								</MainButton>
							</Stack>
						</Center>
						<VStack align={"start"} flex={2}>
							<DocumentsUploader onFileAccepted={(file) => onFileInput(file)} />
							{!!cvUrl && <PdfViewer fileUrl={cvUrl} />}
						</VStack>
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton
						borderRadius={"100%"}
						borderWidth={"2px"}
						borderColor={"gray.500"}
						p={4}
					/>
					<ModalBody>
						<Box pt={10} textAlign={"center"}>
							<Heading size={"sm"} m={0} mb={2}>
								Looks like you already have an account on Elkho Group platform
							</Heading>
							<Heading size={"sm"} fontWeight={"normal"} m={0} mb={4}>
								Please sign in to your existing Elkho Group account to apply for
								this job offer. If you have forgotten your details click the
								button below to receive a password reset link
							</Heading>
							<Button
								bg={buttonBg}
								color={activeColor}
								onClick={() => {
									setHaveAccount(true);
									onClose();
								}}
								mb={4}
							>
								Log in
							</Button>
							<Button
								bg={buttonBg}
								color={activeColor}
								onClick={() => {
									resetPasswordQuery.mutate({ login: tempEmail });
								}}
								mb={2}
							>
								Reset password
							</Button>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Stack
							direction={{ sm: "column", lg: "row" }}
							justify={"space-between"}
							w={"100%"}
						>
							<Button variant="ghost" onClick={onClose}>
								Cancel
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default CandidateCreation;
