import { useState } from "react";

const useConditionsCheck = () => {
	const [allRequiredChecked, setAllRequiredChecked] = useState(false);

	const onConditionsChange = (
		conditions: { isChecked: boolean; requried?: boolean }[],
	) => {
		const someRequiredNotChecked = conditions.some(
			(condition) => !condition.isChecked && condition.requried,
		);
		setAllRequiredChecked(!someRequiredNotChecked);
	};

	return { allRequiredChecked, onConditionsChange };
};

export default useConditionsCheck;
