import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { ListResponse } from "@interfaces/types";
import {
	ICreateJobOfferForm,
	IJobOffer,
	IJobOfferFilters,
	IJobOfferLocationItem,
	IUpdateJobOfferData,
	RobopostPublishForm,
} from "@interfaces/api/job-offers.types";
import convertToParams from "@utils/convert-to-url-params";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { writeToStorage } from "@utils/local-storage";
import {
	LSFiltersCandidateJobOffers,
	LSFiltersJobOffers,
	LSPaginationCandidateJobOffers,
	LSPaginationJobOffers,
	LSSortingCandidateJobOffers,
	LSSortingJobOffers,
} from "@definitions/local-storage.keys";
import { IApiError } from "@interfaces/api/api.types";

export const createJobOffer = async (
	form: Partial<ICreateJobOfferForm>,
): Promise<IJobOffer> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IJobOffer> = await axios.post(`JobOffer`, form);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getJobOffer = async (id?: number): Promise<IJobOffer> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IJobOffer> = await axios.get(`JobOffer/${id}`);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getJobOffers = async (options?: {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: IJobOfferFilters;
}): Promise<ListResponse<IJobOffer>> => {
	const { pagination, filters, sorting } = options || {};
	try {
		const params = convertToParams({ pagination, sorting, filters });
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IJobOffer>> = await axios.get(
			`JobOffer/List`,
			{ params },
		);
		return resp.data;
	} catch (err: any) {
		console.error(err);
		writeToStorage(LSFiltersJobOffers, null);
		writeToStorage(LSPaginationJobOffers, null);
		writeToStorage(LSSortingJobOffers, null);
		writeToStorage(LSFiltersCandidateJobOffers, null);
		writeToStorage(LSPaginationCandidateJobOffers, null);
		writeToStorage(LSSortingCandidateJobOffers, null);
		throw err.response;
	}
};

export const getJobOfferLocations = async (): Promise<
	ListResponse<IJobOfferLocationItem>
> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IJobOffer>> = await axios.get(
			`JobOffer/Locations`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getJobOffersForCompany = async (
	companyId: number,
): Promise<ListResponse<IJobOffer>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IJobOffer>> = await axios.get(
			`Company/${companyId}/JobOffers`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const updateJobOffer = async (
	data: IUpdateJobOfferData,
): Promise<IJobOffer> => {
	try {
		const { id, form } = data;
		const axios = axiosClient();
		const resp: AxiosResponse<IJobOffer> = await axios.patch(
			`JobOffer/${id}`,
			form,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const deleteJobOffer = async (id: number): Promise<void> => {
	try {
		const axios = axiosClient();
		await axios.delete(`JobOffer/${id}`);
	} catch (err: any) {
		throw err.response;
	}
};

export const exportJobOffersToFile = async (options?: {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: IJobOfferFilters;
}): Promise<string> => {
	const { pagination, filters, sorting } = options || {};
	try {
		const params = convertToParams({ pagination, sorting, filters });
		const axios = axiosClient();
		const resp: AxiosResponse<string> = await axios.get(`Exporter/JobOffers`, {
			params,
		});
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getRobopostFieldValues = async (params: {
	fieldName: string;
}): Promise<{ items: {label: string, id: string}[] }> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<string> = await axios.get(
			`Robopost/Fields/${params.fieldName}`,
		);
		const items = Object.entries(JSON.parse(resp.data)).map(([id, label]) => ({id, label})) as {label: string, id: string}[]
		return { items };
	} catch (err: any) {
		throw err.response;
	}
};

export const createJobOfferRobopost = async (data: {
	jobOfferId: number;
	form: RobopostPublishForm;
}): Promise<IJobOffer> => {
	try {
		const { jobOfferId, form } = data;
		const axios = axiosClient();
		const resp: AxiosResponse<IJobOffer> = await axios.post(
			`JobOffer/Robopost/${jobOfferId}`,
			form,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const publishJobOfferRobopost = async (data: {
	jobOfferId: number;
}): Promise<IApiError["data"]> => {
	try {
		const { jobOfferId } = data;
		const axios = axiosClient();
		const resp: AxiosResponse<IApiError["data"]> = await axios.post(
			`JobOffer/Robopost/${jobOfferId}/Publish`,
			{},
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const unpublishJobOfferRobopost = async (data: {
	jobOfferId: number;
}): Promise<void> => {
	try {
		const { jobOfferId } = data;
		const axios = axiosClient();
		const resp: AxiosResponse<void> = await axios.delete(
			`JobOffer/Robopost/${jobOfferId}`,
			{},
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};